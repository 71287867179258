<template>
  <main
    class="w-full rounded-xl shadow-xl sm:w-10/12 md:w-8/12 xl:w-7/12 mx-auto bg-white"
  >
    <div class="w-full px-6 py-8">
      <div class="w-full flex items-center justify-between">
        <h4 class="text-xl md:text-3xl font-medium">Manage Account</h4>
        <div class="flex gap-2 items-center">
          <easiButton
            @click="switchAccountModal = true"
            size="small"
            color="primary"
            class="py-1"
          >
            <div class="flex items-center px-4 py-1">
              <img class="inline md:mr-1" src="@/assets/icons/newSwitch.svg" />
              <span class="hidden md:inline-block ml-1"> Switch Account </span>
            </div>
          </easiButton>
          <easiButton
            @click="addAccountModal = true"
            size="small"
            variant="outlined"
            color="primary"
            class="py-1 border-dark-200 hover:text-black"
          >
            <div class="flex items-center px-4 py-1">
              <!-- <img class="inline md:mr-1" src="@/assets/icons/newGreenPlus.svg" /> -->
              <i class="fas fa-plus md:mr-2"></i>

              <span class="hidden md:inline-block ml-1"> Add More </span>
            </div>
          </easiButton>
        </div>
      </div>

      <hr class="border border-newGrey my-7" />

      <!-- <easiLoader v-if="loading.account" class="" /> -->

      <div class="flex flex-col w-full gap-4">
        <!-- <easiLoader v-if="loading.select" class="" /> -->
        <div
          v-for="company in companyData"
          :key="company._id"
          :class="
            company._id === activeCompany._id
              ? 'shadow-md border sm:border border-primary bg-newLimeGreen'
              : 'border border-dark-200'
          "
          class="relative cursor-pointer flex items-center gap-4 md:gap-6 rounded-xl p-6 w-full"
        >
          <div
            class="absolute right-4 top-6 flex gap-1 sm:gap-4 md:gap-6 items-center"
          >
            <div class="flex">
              <div
                v-if="company._id === activeCompany._id"
                class="text-success flex items-center gap-1 sm:gap-2 text-base font-medium bg-white p-1 px-2 rounded"
              >
                <i class="fas fa-circle"></i>
                Active
              </div>
            </div>

            <div class="relative group">
              <div
                class="flex flex-col gap-1 cursor-pointer px-2"
                @click="toggleShowOptions(company._id)"
              >
                <span class="w-1 h-1 rounded-full bg-primary"></span>
                <span class="w-1 h-1 rounded-full bg-primary"></span>
                <span class="w-1 h-1 rounded-full bg-primary"></span>
              </div>
              <div
                v-show="showOptions === company._id"
                class="flex z-10 flex-col bg-white shadow-md absolute w-32 text-sm right-0"
              >
                <div
                  @click="switchAccount(company)"
                  class="py-2 px-3 hover:bg-info hover:text-white cursor-pointer transition ease-in-out"
                >
                  Switch Account
                </div>
                <!-- <div
                  @click="(deleteWarning = true), (deleteId = company._id)"
                  class="py-2 px-3 hover:bg-info hover:text-white cursor-pointer transition ease-in-out"
                >
                  Delete
                </div> -->
              </div>
            </div>
          </div>

          <div
            class="overflow-hidden bg-primary border w-14 h-14 sm:w-20 sm:h-20 rounded-full text-white flex items-center justify-center font-bold my-2 sm:my-0"
          >
            <span
              v-if="company.registrationType === 'COMPANY'"
              class="w-full h-full flex justify-center items-center"
            >
              <img
                v-if="company.company.logo"
                :src="company.company.logo"
                alt=""
                class="object-cover w-full h-full rounded-full"
              />
              <span v-else class="uppercase">{{
                getAvatar(company.company).name
              }}</span>
            </span>
            <div v-else class="w-full h-full flex justify-center items-center">
              <img
                v-if="companyAdmin.companyAdmin.pfp"
                :src="companyAdmin.companyAdmin.pfp"
                alt=""
                class="object-cover w-full h-full rounded-full"
              />
              <span v-else class="uppercase">{{
                getAvatar(company.company).name
              }}</span>
            </div>
          </div>

          <div class="flex flex-col">
            <span class="font-bold text-base sm:text-xl">{{
              company.company.companyName
            }}</span>
            <span class="text-sm font-medium text-grey">{{
              company.registrationType === "COMPANY"
                ? "Business Account"
                : "Personal Account"
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      @click="showOptions = null"
      :class="!showOptions ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>

    <easiModal
      v-if="switchAccountModal === true"
      @close="switchAccountModal = false"
    >
      <template v-slot:header>
        <h1
          class="capitalize font-bold md:font-medium text-headerText text-2xl md:text-3xl"
        >
          Switch Account
        </h1>
      </template>

      <div class="px-6">
        <h5
          class="text-center text-dark-800 text-base md:text-lg font-bold mb-4"
        >
          Please Select Account
        </h5>

        <div class="flex flex-col w-full gap-4 max-h-56 overflow-y-auto">
          <div
            v-for="company in companyData"
            :key="company._id"
            @click="selectedId = company._id"
            :class="
              selectedId === company._id
                ? 'border border-primary bg-newLimeGreen'
                : 'border border-secondary-crepe'
            "
            class="relative text-sm shadow-md cursor-pointer flex items-center gap-2 rounded-xl p-4 w-full"
          >
            <span class="">
              <img
                v-if="selectedId === company._id"
                src="@/assets/icons/check-active-green.svg"
                alt=""
              />
              <img v-else src="@/assets/icons/check-null.svg" alt="" />
            </span>

            <span>
              {{ company.company.companyName }}
              {{
                company.registrationType === "COMPANY"
                  ? "Business Account"
                  : "Personal Account"
              }}</span
            >
          </div>
        </div>

        <easiButton
          :loading="loading.select"
          @click="selectAccount"
          block
          class="mb-6 mt-8"
        >
          Submit
        </easiButton>
      </div>
    </easiModal>

    <add-account v-if="addAccountModal" @close="addAccountModal = false" />

    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span>Are you sure you want to remove account?</span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full"
              :class="loading.delete ? 'pointer-events-none' : ''"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              @click="deleteAccount"
              class="w-40 min-w-full"
              :loading="loading.delete"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiRoundLoader v-if="loading.account" />
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Account deleted successfully</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

import AddAccount from "@/components/Settings/Edit/AddAccount.vue";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();

const { log } = helperFunctions;

const activeCompany = computed(() => store.getCompanyDetails);
const companyAdmin = computed(() => store.getCompanyAdmin);
const companyData = computed(() => {
  const arr = [];

  store.allCompanyDetails &&
    store.allCompanyDetails.forEach((el) => {
      if (el._id === activeCompany.value._id) {
        arr.unshift(el);
      } else {
        arr.push(el);
      }
    });

  return arr;
});

console.log("Manage Companies");
log(companyData.value);

const selectedId = ref(null);
const deleteId = ref(null);
const showOptions = ref(null);

const loading = ref({
  account: false,
  delete: false,
  select: false,
});

const deleteWarning = ref(false);
const updateSuccess = ref(false);
const switchAccountModal = ref(false);
const addAccountModal = ref(false);

onMounted(() => {
  selectedId.value = activeCompany.value._id;
});

const getAvatar = (comp) => {
  let name = "",
    color;
  // Get color
  const colors = [
    "#D32F2F",
    "#189F00",
    "#0A244F",
    "#E4572E",
    "#E0A409",
    "#184EA8",
    "#F39C36",
    "#4f46e5",
  ];

  const randomNumber = Math.floor(Math.random() * 5);
  color = colors[randomNumber + 1];

  if (comp) {
    // log(comp.companyName.split(" ").length > 1);
    const split1 =
      comp && comp.companyName.split(" ")
        ? comp && comp.companyName.split(" ")[0].charAt(0)
        : comp && comp.companyName.charAt(1);

    const split2 =
      comp && comp.companyName.split(" ").length > 1
        ? comp && comp.companyName.split(" ")[1].charAt(0)
        : comp && comp.companyName.charAt(1);

    name = `${split1}${split2}`;
  } else {
    name = "";
  }

  return {
    color,
    name,
  };
};

const toggleShowOptions = (index) => {
  showOptions.value = showOptions.value === index ? null : index;
};

const switchAccount = async (account) => {
  if (!account.selectedCompany) {
    loading.value.account = true;
    selectedId.value = account._id;
    await selectAccount();

    toggleShowOptions(null);
  } else {
    toast.error("Account already active");
  }
};

const selectAccount = () => {
  if (activeCompany.value._id !== selectedId.value) {
    loading.value.select = true;
    try {
      mutate({
        endpoint: "SelectCompany",
        service: "SETTINGS",
        data: { companyId: selectedId.value },
      })
        .then((res) => {
          if (res.success) {
            loading.value.select = false;
            switchAccountModal.value = false;
            loading.value.account = false;

            queryCompany();
            queryCompanyAdmin();
            queryEmployees();
            queryWalletBalance();
            queryAllowance();
            queryBonusOrDed();
            queryBonus();
            queryDeduction();
            queryAutomations();

            toast.success("Account switched successfully");

            // window.location.reload();
          }
        })
        .catch((e) => {
          console.log(e);
          loading.value.select = false;
          loading.value.account = false;
        });
    } catch (e) {
      loading.value.select = false;
      loading.value.account = false;
      console.log(e);
    }
  } else {
    toast.error("Account already active");
  }
};

const deleteAccount = (index) => {
  console.log(deleteId.value);
  loading.value.delete = true;

  try {
    mutate({
      endpoint: "DeleteCompany",
      service: "SETTINGS",
      data: { companyId: deleteId.value },
    })
      .then((res) => {
        if (res.success) {
          loading.value.delete = false;
          updateSuccess.value = true;
          deleteWarning.value = false;

          setTimeout(() => {
            updateSuccess.value = false;
            queryCompany();

            // window.location.reload();
          }, 500);
          // toast.success("Bank Record Updated Successfully");
        }
      })
      .catch((e) => {
        console.log(e);
        loading.value.delete = false;
      });
  } catch (e) {
    loading.value.delete = false;
    console.log(e);
  }
};

const queryCompany = async () => {
  await query({
    endpoint: "FetchCompaniesByAdminId",
    service: "SETTINGS",
    storeKey: "companyDetails",
  });
};

const queryCompanyAdmin = async () => {
  await query({
    endpoint: "FetchCompanyAdmin",
    service: "SETTINGS",
    storeKey: "companyAdmin",
  });
};

const queryWalletBalance = async () => {
  try {
    query({
      endpoint: "ViewWalletBalance",
      payload: {
        input: {
          companyId: "",
        },
      },
      service: "PAYROLL",
      storeKey: "walletBalance",
    });
  } catch (err) {
    console.log(err);
  }
};

const queryAllowance = () => {
  query({
    endpoint: "ListAllowance",
    payload: {
      paging: {
        limit: 1000,
        skip: 0,
        search: null,
      },
    },
    service: "PAYROLL",
    storeKey: "allowance",
  });
};

const queryEmployees = async () => {
  query({
    endpoint: "ListEmployees",
    payload: {
      paging: {
        limit: 1000,
        skip: 0,
        search: "",
        sort: { firstName: "ASC" },
      },
      filter: [],
    },
    service: "EMP",
    storeKey: "listEmployees",
  });
};

const queryBonusOrDed = async () => {
  try {
    query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Bonus",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
  } catch (err) {
    console.log(err);
  }
};

const queryBonus = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Bonus",
        },
      },
      service: "PAYROLL",
      storeKey: "bonuses",
    });
  } catch (err) {
    console.log(err);
  }
};
const queryDeduction = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Deduction",
        },
      },
      service: "PAYROLL",
      storeKey: "deductions",
    });
  } catch (err) {
    console.log(err);
  }
};
const queryAutomations = async () => {
  try {
    await query({
      endpoint: "ListPayrollAutomations",
      service: "PAYROLL",
      storeKey: "payrollAutomations",
    });
  } catch (err) {
    console.log(err);
  }
};
</script>

<style></style>
